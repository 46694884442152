// extracted by mini-css-extract-plugin
export var column = "UserListCloudRack__column__GpNCi";
export var flex = "UserListCloudRack__flex__WQuAM";
export var flexColumn = "UserListCloudRack__flexColumn__RtCed";
export var gap1 = "UserListCloudRack__gap1__Dp3en";
export var gap2 = "UserListCloudRack__gap2__vceJt";
export var gap3 = "UserListCloudRack__gap3__ujKnB";
export var gap4 = "UserListCloudRack__gap4__GIGrX";
export var gap5 = "UserListCloudRack__gap5__Dzpml";
export var layout = "UserListCloudRack__layout__CeGU0";
export var row = "UserListCloudRack__row__dAG3Z";
export var titleMain = "UserListCloudRack__titleMain__shwZh";
export var wrapper = "UserListCloudRack__wrapper__YUN6I";