// extracted by mini-css-extract-plugin
export var column = "UserListCloudRackTabs__column__W2VkA";
export var flex = "UserListCloudRackTabs__flex__lPXo9";
export var flexColumn = "UserListCloudRackTabs__flexColumn__OMdfK";
export var gap1 = "UserListCloudRackTabs__gap1__IE6nd";
export var gap2 = "UserListCloudRackTabs__gap2__WU7ct";
export var gap3 = "UserListCloudRackTabs__gap3__LxaPy";
export var gap4 = "UserListCloudRackTabs__gap4__i23f7";
export var gap5 = "UserListCloudRackTabs__gap5__qO50a";
export var row = "UserListCloudRackTabs__row__DsKmO";
export var tab = "UserListCloudRackTabs__tab__SG0C2";
export var tabActive = "UserListCloudRackTabs__tabActive__X_e3D";
export var tabs = "UserListCloudRackTabs__tabs__QqBpK";